import { ref, watch } from '@vue/composition-api';
import toast from '@/utils/toast';
import useJwt from '@/auth/jwt/useJwt';

export default function useContractTemplate(props, emit, refFormObserver) {
  const toastification = toast();

  const refModal = ref(null);
  const refForm = ref(refFormObserver);
  const apartment = ref(null);
  const contractTemplate = ref('');
  const params = ref([]);

  const fetchData = () => {
    useJwt.getContractParams().then(response => {
      params.value = response.data;
    }).catch(error => {
      toastification.showToastError(error);
    });
  };

  const fetchApartmentConfiguration = val => {
    if (val) {
      useJwt.getApartmentConfiguration({ apartmentId: val.id })
        .then(response => {
          contractTemplate.value = response.data.contractTemplate;
        })
        .catch(error => {
          toastification.showToastError(error);
        });
    }
  };

  const onSubmit = () => {
    useJwt.updateApartmentConfiguration({ apartmentId: apartment.value.id, contractTemplate: contractTemplate.value })
      .then(() => {
        toastification.showToastUpdateSuccess();
      }).catch(error => {
        toastification.showToastError(error, refForm);
      });
  };

  watch(apartment, val => {
    if (val) {
      fetchApartmentConfiguration(val);
    } else {
      contractTemplate.value = '';
    }
  });

  return {
    fetchData,
    refModal,
    params,
    refForm,
    apartment,
    contractTemplate,
    onSubmit,
  };
}
